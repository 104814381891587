/* type in flow */
export enum NodePosition {
  HEAD = 0,
  TAIL = 1,
  MIDDLE = 2,
  CONNECT = 3,
  SSO = 4,
  MULTIPLE = 5,
}

/* action_type in flow */
export enum NodeType {
  UNDEFINED = -1,
  TEXT = 0,
  IMAGE = 1,
  FORM = 2,
  CONDITION = 3,
  POLL = 4,
  FINAL_STANDARD = 5,
  FINAL = 6,
  FINAL_THANK_YOU = 7,
  RATING = 8,
  REACTION = 9,
  REVIEW = 10,
  RESULT_POLL = 11,
  RESULT_RATING = 12,
  RESULT_REACTION = 13,
  OTP = 14,
}

export const NodeFinalTypes = [
  NodeType.FINAL,
  NodeType.FINAL_STANDARD,
  NodeType.FINAL_THANK_YOU,
]

/* type if nodes.intent */
export enum NodeIntentType {
  BOT_TEXT = 'bot-text',
  BOT_IMAGE = 'bot-image',
  BOT_BUTTON = 'bot-button',
  BOT_URL = 'bot-url',
  BOT_RECALCULATE = 'bot-recalculate',
  BOT_BOOKING = 'bot-booking',
  BOT_MAIN_TITLE = 'bot-main-title',
  BOT_MAIN_IMAGE = 'bot-main-image',
  BOT_MAIN_BODY = 'bot-main-body',
  BOT_MAIN_BUTTON = 'bot-main-button',
  BOT_SUB_TITLE = 'bot-sub-title',
  BOT_SUB_BODY = 'bot-sub-body',
  BOT_LANDING_PAGE_URL = 'bot-landing-page-url',
  BOT_LANDING_PAGE_BUTTON = 'bot-landing-page-button',
  BOT_RISK_SCREEN = 'bot-risk-screen',
  BOT_RELATED_ARTICLE = 'bot-related-article',
  THANK_YOU_IMAGE = 'thank-you-image',
  THANK_YOU_TITLE = 'thank-you-title',
  THANK_YOU_BODY = 'thank-you-body',
  TOTAL_LINE = 'total-line',
  BOT_SUBMISSION = 'bot-submission',

  /** intents fake for sso block */
  SSO_INTENT = 'sso-intent',
}

/* type if nodes.action */
export enum NodeActionType {
  ACTION_CONDITION = 'action-condition',
  ACTION_INTERNAL = 'action-internal',
  ACTION_EXTERNAL = 'action-external',
  ACTION_SSO_REDIRECT = 'action-sso-redirect',
  ACTION_MULTIPLE_BUTTON = 'action-multiple-button',
  USER_CHOICE_TEXT = 'user-choice-text',
  USER_CHOICE_IMAGE = 'user-choice-image',
  USER_SUBMIT_TEXT = 'user-submit-text',
  USER_SUBMIT_NUMBER = 'user-submit-number',
  USER_SUBMIT_NAME = 'user-submit-name',
  USER_SUBMIT_PHONE = 'user-submit-phone',
  USER_SUBMIT_EMAIL = 'user-submit-email',
  USER_SUBMIT_DATE = 'user-submit-date',
  USER_SUBMIT_CONFIRM = 'user-submit-confirm',
  USER_SUBMIT_SEND = 'user-submit-send',
  USER_SUBMIT_RESEND = 'user-submit-resend',
  USER_SUBMIT_ADDRESS = 'user-submit-address',
  USER_SUBMIT_ADDRESS_PROVINCE = 'user-submit-address-province',
  USER_SUBMIT_ADDRESS_DISTRICT = 'user-submit-address-district',
  USER_SUBMIT_ADDRESS_COMMUNE = 'user-submit-address-commune',
  USER_SUBMIT_BMI = 'user-submit-bmi',
  USER_SUBMIT_BMI_HIDDEN = 'user-submit-bmi-hidden',
  USER_SUBMIT_TERMS = 'user-submit-terms',
  USER_SUBMIT_GENDER = 'user-submit-gender',
  USER_SUBMIT_OTP = 'user-submit-otp',
  POLL_CHOICE = 'poll-choice',
  POLL_CHOICE_IMAGE = 'poll-choice-image',
  POLL_BUTTON = 'poll-button',
  RATING_STAR = 'rating-star',
  RATING_DESCRIPTION = 'rating-description',
  RATING_BUTTON = 'rating-button',
  LIKE_CHOICE = 'like-choice',
  DISLIKE_CHOICE = 'dislike-choice',
  REVIEW_CHOICE = 'review-choice',
  REVIEW_DESCRIPTION = 'review-description',
  REVIEW_BUTTON = 'review-button',
  RESULT_BUTTON = 'result-button',
  POLL_RESULT = 'poll-result',
  USER_SUBMIT_GOOGLE_API_ADDRESS = 'user-submit-google-api-address',
}

export enum NodeMultipleOptions {
  UNLIMITED = 0,
  EXACT_NUMBER = 1,
  RANGE = 2,
}

export const ELEMENT_CONTROL_INPUT_TYPE: { [key: string]: string } = {
  [NodeActionType.USER_SUBMIT_EMAIL]: 'email',
  [NodeActionType.USER_SUBMIT_TEXT]: 'text',
  [NodeActionType.USER_SUBMIT_NAME]: 'name',
  [NodeActionType.USER_SUBMIT_NUMBER]: 'number',
  [NodeActionType.USER_SUBMIT_ADDRESS]: 'address',
  [NodeActionType.USER_SUBMIT_PHONE]: 'tel',
  [NodeActionType.USER_SUBMIT_OTP]: 'tel',
  [NodeActionType.USER_SUBMIT_DATE]: 'date',
  [NodeActionType.USER_SUBMIT_CONFIRM]: 'confirm',
  [NodeActionType.USER_SUBMIT_SEND]: 'submit',
  [NodeActionType.USER_SUBMIT_TERMS]: 'terms',
  [NodeActionType.USER_SUBMIT_ADDRESS_PROVINCE]: 'province',
  [NodeActionType.USER_SUBMIT_ADDRESS_DISTRICT]: 'district',
  [NodeActionType.USER_SUBMIT_ADDRESS_COMMUNE]: 'commune',
  [NodeActionType.USER_SUBMIT_BMI]: 'bmi',
  [NodeActionType.USER_SUBMIT_GENDER]: 'gender',
  [NodeActionType.POLL_CHOICE]: 'poll',
  [NodeActionType.POLL_CHOICE_IMAGE]: 'poll',
  [NodeActionType.RATING_STAR]: 'rating',
  [NodeActionType.RATING_DESCRIPTION]: 'text',
  [NodeActionType.LIKE_CHOICE]: 'like',
  [NodeActionType.DISLIKE_CHOICE]: 'dislike',
  [NodeActionType.REVIEW_CHOICE]: 'review',
  [NodeActionType.USER_SUBMIT_GOOGLE_API_ADDRESS]: 'google-api-address',
}
